.bg {
   background: #93ecaf;
   background: linear-gradient(45deg, #93ecaf 0%, #f3f98b 100%);
   background: -moz-linear-gradient(45deg, hwb(139 58% 7%) 0%, #f3f98b 100%);
   background: -webkit-linear-gradient(45deg, #93ecaf 0%, #f3f98b 100%);
   filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#95ECB0", endColorstr="#F3F98A", GradientType=1 );
   min-height: 100vh;
}

/*===SCROLL BAR===*/

::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   border-radius: 10px;
   background-color: #e7e7e7;
   
}

::-webkit-scrollbar {
   width: 6px;
   background-color: #e7e7e7;
   
}

::-webkit-scrollbar-thumb {
   border-radius: 5px;
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: var(--darkColor);
   
}
