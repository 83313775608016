.display {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   width: 100vw;
   padding: 30px 20px;
}

.textBox {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   text-align: left;
   padding: var(--mb-3);
   min-height: 400px;
   width: 60%;
   /* border-radius: 20px; */
   background: rgba(255, 255, 255, 0.31);
   border-radius: 16px;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(13.5px);
   -webkit-backdrop-filter: blur(13.5px);
   border: 1px solid rgba(255, 255, 255, 0.28);
}

.imgBox {
   align-items: center;
   width: 300px;
}

.detail_container {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: center;
   width: 100%;
}

.list {
   width: 100%;
   text-align: left;
   padding-left: var(--mb-3);
   font-family: var(--infoFont);
   font-size: 20px;
   list-style-type: circle;
   color: var(--lightColor);
   margin-bottom: 0.5rem;
}

.list_item {
   line-height: var(--mb-4);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
   .display {
      flex-direction: column-reverse;
      padding: 40px 20px;
   }

   .textBox {
      margin-top: 20px;
      width: 90%;
   }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
   .list {
      font-size: 15px;
   }
}
